import React from 'react';
import faqImage from '../../assets/img/faq-img.png';

const FAQ = () => (
  <div id="faq" className="faq__wrapper">
    <section className="faq">
      <div className="faq__container">
        <h2 className="faq__title">FAQ: 常见问题</h2>
        <ul className="faq__list">
          <li className="faq__item">
            <h3 className="faq__item-title">
              什么是 NFT?
            </h3>
            <p className="faq__item-text">
              NFT 代表“不可替代的代币”，是一种奇特的说法，表示它是一种独特的、独一无二的数字项目，用户可以购买、拥有和交易。 一些 NFT 的主要功能是数字艺术并且看起来很酷，一些提供额外的实用程序，例如独家访问网站或参加活动，将其视为一件罕见的艺术品，也可以充当“会员”卡。
            </p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">
              如何购买星座头像NFT盲盒？
            </h3>
            <p className="faq__item-text">
              <ol className="faq__item-ol">
                <li>
                  1. 购买星座NFT头像需要链接NEAR钱包账号，”链接钱包“按键可在登录首页找到。
                </li>
                <li>
                  2. 钱包登录后，首页点击 “立即购买NFT” 按键进行购买。提供单个购买与批量购买两个选项，你可以选择购买1个，或者一次性购买购买10个。享受开盲盒的同道大叔星座宝宝们，千万不要错过批量购买NFT盲盒的快感！
                </li>
                <li>
                  3. 当铸币开始时，界面会跳转到NEAR钱包支付页面，完成支付后，您的星座头像NFT盲盒将会出现在“我的NFT” ，欢迎你已正式加入同道星座学院！
                </li>
                <li>
                  4. 开盲盒，购买的NFT盲盒将随机发放，在“我的NFT” 中享受开盲盒的重重惊喜与乐趣吧！
                </li>
                <li>
                  5. 您还可以购买生成NFT盲盒礼品链接，此功能让你能够与小伙伴们分享收集星座头像与开盲盒的喜悦！
                </li>
              </ol>
            </p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">
              同道星座学院NFT是一项不错的投资吗？
            </h3>
            <p className="faq__item-text">
              <ol className="faq__item-ol">
                <li>
                  随着时间推移，星座头像NFT的价值会更加凸显。同道大叔拥有海量粉丝（微博1900万、微信900万、抖音160万），这是同道大叔发行的第一个NFT头像，未来会非常稀缺
                </li>
                <li>
                  1. 同道大叔影响力在持续提升，并且拥抱元宇宙，未来会有更多人知晓这个IP，同道大叔头像的价值只会更高
                </li>
                <li>
                  2. 未来将引入以星座头像为中心的边玩边赚钱的游戏，星座头像持有者获得专属特权
                </li>
                <li>
                  3. 未来有机会获得同道大叔星座学院漫画推送的专属内容
                </li>
                <li>
                  4. 集齐12款星座可加入星座达人俱乐部DAO，享受社区专属空投与福利
                </li>
                <li>
                  5. 成立星座学院俱乐部DAO，以DAO的名义洽谈同道大叔每期星座文章转发授权，推广星座文化，开发更多星座应用，培养更多DAO成员
                </li>
              </ol>
            </p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">
              会有二级市场吗？
            </h3>
            <p className="faq__item-text">
              是的，会有二级市场流通。同道星座学院头像NFT将在上线后不久在Paras.id 上架，以便您可以尽快进行交易。我们在NEAR的发行伙伴TenkBay 已经与 Paras.id 合作多次，这将是无缝体验。 我们不支持也不鼓励无序的炒作行为。我们所乐见的是未能有幸抽到自己星座头像的宝宝们都能在自由市场选购到心仪的收藏。
            </p>
          </li>
          <li className="faq__item">
            <h3 className="faq__item-title">
              会有版税吗？
            </h3>
            <p className="faq__item-text">
              有的，版税定为 10%。我们将把其中的 2% 贡献回同道星座学院社区，用于未来的学院扩展与建设（接受社区监督与审查）。余下的将用于发展与推广星座文化，开发星座文创产品。
            </p>
          </li>
        </ul>

        <img
          className="faq__img"
          src={faqImage}
          alt='faq'
        />
      </div>
    </section>
  </div>
);

export default FAQ;
